<template>
  <div id="addVehicleDialog">
    <el-dialog
      :title="isAdd ? '新增商圈' : '编辑商圈'"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @open="openDialog"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="80px"
      >
        <el-form-item label="商圈名称" prop="businessCircleName">
          <el-input
            v-model="addForm.businessCircleName"
            placeholder="请输入商圈名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商圈地址" prop="businessCircleAddress">
          <el-input
            v-model="addForm.businessCircleAddress"
            placeholder="请输入商圈地址"
          ></el-input>
        </el-form-item>
        <gaode-map
          @mapClick="mapClick"
          ref="gaodeMap"
          :initCoord="defaultCoord"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    gaodeMap: () => import("@/components/gaode-map.vue"),
  },
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      isAdd: true,
      addForm: {
        businessCircleName: "",
        businessCircleAddress: "",
        businessCircleLng: "",
        businessCircleLat: "",
      },
      addFormRules: {
        businessCircleName: [
          { required: true, message: "请输入商圈名称", trigger: "blur" },
        ],
        businessCircleAddress: [
          { required: true, message: "请输入商圈地址", trigger: "blur" },
        ],
      },
      defaultCoord: {
        lng: "",
        lat: "",
      },
    };
  },
  methods: {
    openDialog() {
      this.$nextTick(() => {
        if (!this.isAdd) {
          Object.assign(this.defaultCoord, {
            lng: this.addForm.businessCircleLng,
            lat: this.addForm.businessCircleLat,
          });
        }
      });
    },
    reset() {
      this.isAdd = true;
      this.$refs.gaodeMap.reset();
      this.addForm = {
        businessCircleName: "",
        businessCircleAddress: "",
        businessCircleLng: "",
        businessCircleLat: "",
      };
      this.$refs["addForm"].resetFields();
    },
    mapClick(params) {
      Object.assign(this.addForm, {
        businessCircleAddress: params.address,
        businessCircleLng: params.longitude,
        businessCircleLat: params.latitude,
      });
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      if (!this.addForm.businessCircleLng || !this.addForm.businessCircleLat) {
        this.$message.warning("请在地图上选取坐标");
        return;
      }
      try {
        this.loading = true;
        let url = "/business/circle/add";
        if (!this.isAdd) {
          url = "/business/circle/edit";
        }
        let res = await this.$http.post(url, {
          ...this.addForm,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addVehicleDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
