<template>
  <div id="vehicleList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>商圈列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addBtn"
              type="primary"
              @click="$refs.addBusinessCircleDialog.show = true"
              v-if="buttonList.includes('businessCircleList-addBtn')"
              ><i class="iconfont icon-jiahao"></i> 新建商圈</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column
          prop="businessCircleName"
          label="商圈名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="businessCircleAddress"
          label="商圈地址"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="parkingLotNum"
          label="商圈停车场数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="combination"
          label="商圈剩余车位/总车位(产权/人防)"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="buttonList.includes('businessCircleList-editBtn')"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.businessCircleId)"
              v-if="buttonList.includes('businessCircleList-delBtn')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <add-business-circle-dialog
      ref="addBusinessCircleDialog"
      :getList="getList"
    />
  </div>
</template>
<script>
import addBusinessCircleDialog from "./addBusinessCircleDialog.vue";
export default {
  components: {
    addBusinessCircleDialog,
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    edit(row) {
      this.$refs.addBusinessCircleDialog.addForm = {
        businessCircleName: row.businessCircleName,
        businessCircleAddress: row.businessCircleAddress,
        businessCircleLng: row.businessCircleLng,
        businessCircleLat: row.businessCircleLat,
        businessCircleId: row.businessCircleId,
      };
      this.$refs.addBusinessCircleDialog.isAdd = false;
      this.$refs.addBusinessCircleDialog.show = true;
    },
    del(businessCircleId) {
      this.$confirm("此操作将永久删除该商圈, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(
            `/business/circle/delete/${businessCircleId}`
          );
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    goToDetail(vehicleId) {
      this.$router.push({ name: "vehicleDetail", params: { id: vehicleId } });
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/business/circle/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.combination = `${item.idleLots}/${item.totalLots}(${item.property}/${item.civilDefense})`;
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#vehicleList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addBtn {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
